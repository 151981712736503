// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component138Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_0_1_1_0_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_0_1_1_0_onClick ")
                }  
              };

const bloc0_0_1_1_3_onClick = async (e) => {
                try{ 
                      navigate('/null');
                }
                catch(e){
                  console.log("Error bloc0_0_1_1_3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "bg-primary_color"}     > <div id='bloc0_0'  className={ "text-xl flex flex-row justify-between w-full"}     > <img  id='bloc0_0_0'  className={ "h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/image_2024-11-15_1514451421731680085164.png"   alt="undefined" />
            <div id='bloc0_0_1'  className={ "flex flex-col justify-center"}     > <div id='bloc0_0_1_0'  className={ "font-bold p-4 text-3xl"}     >{`Puppy Yoga`} </div>
              <div id='bloc0_0_1_1'  className={ "p-2 flex flex-row space-x-4"}     > <Link    to="/home"><div id='bloc0_0_1_1_0'      onClick = { bloc0_0_1_1_0_onClick } >{`Accueil`} </div></Link>
                <div id='bloc0_0_1_1_1'       >{`Réservations`} </div>
                <div id='bloc0_0_1_1_2'       >{`E-shop`} </div>
                <Link    to="/null"><div id='bloc0_0_1_1_3'      onClick = { bloc0_0_1_1_3_onClick } >{`A propos`} </div></Link>
                <div id='bloc0_0_1_1_4'       >{`Galerie`} </div>
                <div id='bloc0_0_1_1_5'       >{`Contact`} </div></div></div>
            <div id='bloc0_0_2'  className={ "p-4"}     >{`Rechercher`} </div></div></div><div id='bloc1'  className={ "w-full p-2 bg-white text-lg font-app-1"}     > <div id='bloc1_0'  className={ "font-bold text-4xl pb-5"}     >{`A propos de nous `} </div>
          <div id='bloc1_1'  className={ "p-2 flex justify-center"}     > <img  id='bloc1_1_0'  className={ "rounded-lg w-80 pb-5   object-cover"}  src="https://fs.appisyou.com/users/15/889868-puppy-yoga-image000251731675605971.jpg"   alt="undefined" /></div>
          <div id='bloc1_2'  className={ "flex flex-col"}     > <div id='bloc1_2_0'  className={ "flex justify-center px-20 text-lg text-left max-w-5/6"}     >{`Chez Puppy Yoga, nous croyons au pouvoir du bien-être et à l’énergie positive que les animaux apportent à notre quotidien. C’est pourquoi nous avons créé un concept unique : des cours de yoga avec des chiots ! Notre mission est d'offrir un moment de relaxation, de joie et de sérénité dans un environnement chaleureux et bienveillant, en associant les bienfaits du yoga à la douceur et à la tendresse des chiots.
    `} </div></div>
          <div id='bloc1_3'       > </div></div></>
  
}

export default Component138Page;
