// Imports
          import { Link } from 'react-router-dom'

import InputTextArea from "../../../../components/inputs/InputTextArea";
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component123Page() {
const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_0_1_1_0_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_0_1_1_0_onClick ")
                }  
              };

const bloc0_0_1_1_3_onClick = async (e) => {
                try{ 
                      navigate('/about');
                }
                catch(e){
                  console.log("Error bloc0_0_1_1_3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "bg-primary_color"}     > <div id='bloc0_0'  className={ "text-xl flex flex-row justify-between w-full"}     > <img  id='bloc0_0_0'  className={ "h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/image_2024-11-15_1514451421731680085164.png"   alt="undefined" />
            <div id='bloc0_0_1'  className={ "flex flex-col justify-center"}     > <div id='bloc0_0_1_0'  className={ "font-bold p-4 text-3xl"}     >{`Puppy Yoga`} </div>
              <div id='bloc0_0_1_1'  className={ "p-2 flex flex-row space-x-4"}     > <Link    to="/home"><div id='bloc0_0_1_1_0'      onClick = { bloc0_0_1_1_0_onClick } >{`Accueil`} </div></Link>
                <div id='bloc0_0_1_1_1'       >{`Réservations`} </div>
                <div id='bloc0_0_1_1_2'       >{`E-shop`} </div>
                <Link    to="/about"><div id='bloc0_0_1_1_3'      onClick = { bloc0_0_1_1_3_onClick } >{`A propos`} </div></Link>
                <div id='bloc0_0_1_1_4'       >{`Galerie`} </div>
                <div id='bloc0_0_1_1_5'       >{`Contact`} </div></div></div>
            <div id='bloc0_0_2'  className={ "p-4"}     >{`Rechercher`} </div></div></div><div id='bloc1'  className={ "bg-white p-2 flex justify-center"}     > <div id='bloc1_0'  className={ "flex flex-col justify-center items-center"}     > <div id='bloc1_0_0'  className={ "flex justify-center w-40 h-40 p-3 shadow-lg rounded-3xl bg-primary_color"}     > <div id='bloc1_0_0_0'  className={ "flex flex-row"}     > <img  id='bloc1_0_0_0_0'  className={ "rounded-full   object-cover"}  src="https://fs.appisyou.com/users/15/DSC004151727253314056.jpg"   alt="undefined" /></div></div>
            <div id='bloc1_0_1'  className={ "p-2 text-black"}     >{`Découvrez l'univers du puppy yoga`} </div></div></div><div id='bloc2'  className={ "w-full bg-primary_color font-bold"}     > <div id='bloc2_0'  className={ "text-xl p-5"}     >{`Evènement à venir`} </div></div><div id='bloc3'  className={ "bg-white flex justify-center font-app-2"}     > <div id='bloc3_0'  className={ "flex border-b-2"}     > <div id='bloc3_0_0'       > <img  id='bloc3_0_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
            <div id='bloc3_0_1'       > </div>
            <div id='bloc3_0_2'       > <div id='bloc3_0_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
      `} </div>
              <div id='bloc3_0_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
              <div id='bloc3_0_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
              <div id='bloc3_0_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div></div><div id='bloc4'  className={ "bg-white flex justify-center font-app-2"}     > <div id='bloc4_0'  className={ "flex border-b-2"}     > <div id='bloc4_0_0'       > <img  id='bloc4_0_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
            <div id='bloc4_0_1'       > </div>
            <div id='bloc4_0_2'       > <div id='bloc4_0_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
      `} </div>
              <div id='bloc4_0_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
              <div id='bloc4_0_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
              <div id='bloc4_0_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div></div><div id='bloc5'  className={ "bg-white flex justify-center font-app-2"}     > <div id='bloc5_0'  className={ "flex border-b-2"}     > <div id='bloc5_0_0'       > <img  id='bloc5_0_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
            <div id='bloc5_0_1'       > </div>
            <div id='bloc5_0_2'       > <div id='bloc5_0_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
      `} </div>
              <div id='bloc5_0_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
              <div id='bloc5_0_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
              <div id='bloc5_0_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div></div><div id='bloc6'  className={ "bg-white flex justify-center font-app-2"}     > <div id='bloc6_0'  className={ "flex border-b-2"}     > <div id='bloc6_0_0'       > <img  id='bloc6_0_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
            <div id='bloc6_0_1'       > </div>
            <div id='bloc6_0_2'       > <div id='bloc6_0_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
      `} </div>
              <div id='bloc6_0_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
              <div id='bloc6_0_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
              <div id='bloc6_0_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div></div><div id='bloc7'  className={ "w-full bg-white flex px-20 justify-center p-2"}     > <div id='bloc7_0'  className={ "text-white p-2 text-lg w-40 rounded-2xl text-center bg-secondary_color"}     >{`En savoir plus`} </div></div><div id='bloc8'  className={ "p-2 bg-white flex justify-center"}     > <div id='bloc8_0'       > 
            
            
            <InputTextArea id={"input_bloc8_0_3"} label={"Contacter"} onChange={ (value)=>{
    
    
                      setInternal("Contacter",value)
                   } } value={content["Contacter"]??""} className={"my-2"} placeholder={"undefined"}  />
            <div id='bloc8_0_4'  className={ "text-white bg-black p-3 mt-4"}     >{`Envoyer`} </div></div></div><div id='bloc9'  className={ "w-full bg-black bg-secondary_color flex flex-row justify-center text-white"}     > <div id='bloc9_0'  className={ "p-2 h grid grids-cols-1 md:grid-cols-2 gap-1 lg:grid-cols-3 pb-40"}     > <div id='bloc9_0_0'  className={ " flex flex-col items-start p-4"}     > <div id='bloc9_0_0_0'  className={ "text-xl font-bold"}     >{`xsqcr`} </div>
              <div id='bloc9_0_0_1'  className={ "whitespace-pre-line text-left"}     >{`Contenu`} </div></div>
            <div id='bloc9_0_1'  className={ " flex flex-col items-start p-4"}     > <div id='bloc9_0_1_0'  className={ "font-bold text-xl"}     >{`sqcqsc`} </div>
              <div id='bloc9_0_1_1'  className={ "whitespace-pre-line text-left"}     >{`Contenu`} </div>
              <div id='bloc9_0_1_2'       > </div></div>
            <div id='bloc9_0_2'  className={ " flex flex-col items-start p-4"}     > <div id='bloc9_0_2_0'  className={ "font-bold text-xl"}     >{`cdscddsc`} </div>
              <div id='bloc9_0_2_1'  className={ "whitespace-pre-line text-left"}     >{`Contenu`} </div></div>
            <div id='bloc9_0_3'  className={ "font-bold text-xl text-left p-4 underline"}     >{`Mentions légales`} </div></div></div></>
  
}

export default Component123Page;
