// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component139Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "bg-primary_color"}     > <div id='bloc0_0'  className={ "text-xl flex flex-row justify-between w-full"}     > <img  id='bloc0_0_0'  className={ "h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/image_2024-11-15_1514451421731680085164.png"   alt="undefined" />
            <div id='bloc0_0_1'  className={ "flex flex-col justify-center"}     > <div id='bloc0_0_1_0'  className={ "font-bold p-4 text-3xl"}     >{`Puppy Yoga`} </div>
              <div id='bloc0_0_1_1'  className={ "p-2 flex flex-row space-x-4"}     > <div id='bloc0_0_1_1_0'       >{`Accueil`} </div>
                <div id='bloc0_0_1_1_1'       >{`Réservations`} </div>
                <div id='bloc0_0_1_1_2'       >{`E-shop`} </div>
                <div id='bloc0_0_1_1_3'       >{`A propos`} </div>
                <div id='bloc0_0_1_1_4'       >{`Galerie`} </div>
                <div id='bloc0_0_1_1_5'       >{`Contact`} </div></div></div>
            <div id='bloc0_0_2'  className={ "p-4"}     >{`Rechercher`} </div></div></div><div id='bloc1'  className={ "bg-white flex justify-center font-app-2"}     > <div id='bloc1_0'       > <div id='bloc1_0_0'  className={ "flex border-b-2"}     > <div id='bloc1_0_0_0'       > <img  id='bloc1_0_0_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
              <div id='bloc1_0_0_1'       > </div>
              <div id='bloc1_0_0_2'       > <div id='bloc1_0_0_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
        `} </div>
                <div id='bloc1_0_0_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
                <div id='bloc1_0_0_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
                <div id='bloc1_0_0_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div>
            <div id='bloc1_0_1'  className={ "flex border-b-2"}     > <div id='bloc1_0_1_0'       > <img  id='bloc1_0_1_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
              <div id='bloc1_0_1_1'       > </div>
              <div id='bloc1_0_1_2'       > <div id='bloc1_0_1_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
        `} </div>
                <div id='bloc1_0_1_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
                <div id='bloc1_0_1_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
                <div id='bloc1_0_1_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div>
            <div id='bloc1_0_2'  className={ " p-2 "}     > <div id='bloc1_0_2_0'  className={ "flex border-b-2"}     > <div id='bloc1_0_2_0_0'       > <img  id='bloc1_0_2_0_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
                <div id='bloc1_0_2_0_1'       > </div>
                <div id='bloc1_0_2_0_2'       > <div id='bloc1_0_2_0_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
          `} </div>
                  <div id='bloc1_0_2_0_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
                  <div id='bloc1_0_2_0_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
                  <div id='bloc1_0_2_0_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div></div>
            <div id='bloc1_0_3'  className={ " p-2 "}     > <div id='bloc1_0_3_0'  className={ "flex border-b-2"}     > <div id='bloc1_0_3_0_0'       > <img  id='bloc1_0_3_0_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
                <div id='bloc1_0_3_0_1'       > </div>
                <div id='bloc1_0_3_0_2'       > <div id='bloc1_0_3_0_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
          `} </div>
                  <div id='bloc1_0_3_0_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
                  <div id='bloc1_0_3_0_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
                  <div id='bloc1_0_3_0_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div></div>
            <div id='bloc1_0_4'  className={ " p-2 "}     > <div id='bloc1_0_4_0'  className={ "flex border-b-2"}     > <div id='bloc1_0_4_0_0'       > <img  id='bloc1_0_4_0_0_0'  className={ "p-2 h-40 w-40   object-cover"}  src="https://fs.appisyou.com/users/15/889871-puppy-yoga-paris-des-seances-de-yoga-tout-en-calins-avec-d-adorables-chiots-prochaines-dates1731920501017.jpg"   alt="undefined" /></div>
                <div id='bloc1_0_4_0_1'       > </div>
                <div id='bloc1_0_4_0_2'       > <div id='bloc1_0_4_0_2_0'  className={ "flex font-bold"}     >{`Puppy Yoga Paris, 14h30
          `} </div>
                  <div id='bloc1_0_4_0_2_1'  className={ "flex font-bold"}     >{`mer. 20 nov.`} </div>
                  <div id='bloc1_0_4_0_2_2'  className={ "flex font-bold"}     >{`Adresse communiquée par e-mail`} </div>
                  <div id='bloc1_0_4_0_2_3'  className={ "bg-gray-300 mr-4 p-1 mt-2"}     >{`Acheter des billets`} </div></div></div></div></div></div><div id='bloc2'  className={ "w-full p-2 bg-secondary_color"}     > <div id='bloc2_0'       >{`© 2024 par Puppy Yoga . CGV- Conditions générales d'utilisation - Politique d'annulation`} </div></div></>
  
}

export default Component139Page;
